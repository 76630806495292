<script>
import { ref, onMounted, watch } from "vue"
import { formatDatetimeBR } from "../../../utils/date"
import { acceptTermOfUse, fetchUsersUnacceptedTerms, showTerm } from "./api"
// import { useRouter } from "vue-router"
import bootstrap from "bootstrap/dist/js/bootstrap"
import { Toasts } from "../../../utils/toast-alerts"

export default {
  name: 'TermsOfUseModal',
  setup() {
    // const router = useRouter()
    let unacceptedTerms = ref([])
    let termOfUse = ref({})
    let totalUnacceptedTerms = ref(0)
    let isModalOpened = ref(false)

    async function getUnacceptedTerms(){
      try {
        const data = await fetchUsersUnacceptedTerms()
        unacceptedTerms.value = data.unacceptedTermsOfUse
        totalUnacceptedTerms.value = data.total
      } catch (error) {
        Toasts('error', 'Falha ao carregar termos de uso não aceitos')
      }
    }

    async function acceptTerm() {
      try {
        const params = {
          term_id: termOfUse.value.id
        }
        const { message } = await acceptTermOfUse(params)
        termOfUse.value = {}
        Toasts('success', message)
        getUnacceptedTerms()
      } catch (error) {
        Toasts('error', 'Falha ao carregar termos de uso não aceitos')
      }
    }

    const viewTermOfUse = async (termId) => {
      try {
        const { data } = await showTerm(termId)
        termOfUse.value = data
      } catch (error) {
        Toasts('error', 'Falha ao carregar termos de uso não aceitos')
      }
    }

    const openModal = () => {
      const termsOfUseModal = new bootstrap.Modal(document.getElementById('termsOfUseModal'))
      isModalOpened.value = true
      termsOfUseModal.show()
    }

    const closeModal = () => {
      setTimeout(() => {
        window.location.reload()
      }, 1750);
      // const termsOfUseModal = new bootstrap.Modal(document.getElementById('termsOfUseModal'))
      // termsOfUseModal.hide()
    }

    onMounted(async () => {
      await getUnacceptedTerms()
      if (totalUnacceptedTerms.value > 0) {
        openModal()
      }
    })

    watch(unacceptedTerms, () => {
      if (isModalOpened.value && (unacceptedTerms.value.length === 0)) {
        closeModal()
      }
    })

    return {
      getUnacceptedTerms,
      acceptTerm,
      unacceptedTerms,
      termOfUse,
      totalUnacceptedTerms,
      viewTermOfUse,
      formatDatetimeBR
    }
  },
  components: {

  },
}
</script>
<template>
  <div
    class="modal"
    tabindex="-1"
    role="dialog"
    id="termsOfUseModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="myExtraLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            v-if="termOfUse.title"
            class="modal-title"
          >
            {{ termOfUse.title }}
          </h5>
          <h5
            v-else
            class="modal-title"
          >
            Aceite de Termos de Uso
          </h5>
          <button
            v-if="termOfUse.title"
            type="button"
            class="btn-close"
            id="form-btn-back"
            aria-label="Voltar"
            @click="termOfUse = {}"
          />
          <button
            v-if="unacceptedTerms.length === 0"
            type="button"
            class="btn-close"
            id="form-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div v-if="unacceptedTerms.length === 0">
          <div class="row">
            <div class="col m-3">
              Tudo certo! Você não possui mais nenhum termo de uso não aceito.
            </div>
          </div>
        </div>
        <div v-else-if="!termOfUse.title">
          <form
            class="modal-body"
            @submit.prevent="acceptTerm"
          >
            <div class="row">
              <div class="col-md-12">
                <div
                  v-for="term of unacceptedTerms"
                  :key="term.id"
                  class="mb-3 striped"
                  @click="viewTermOfUse(term.id)"
                >
                  <div class="row">
                    <div class="col">
                      <label
                        class="form-label ms-2"
                        :for="`termTitle${term.id}`"
                      >
                        {{ term.title }}
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col m-2">
                      {{ term.description.substr(0, 100) }} <span v-if="term.description.length > 100">...</span>
                    </div>
                  </div>
                  <span
                    class="ms-2 text-info"
                  >
                    Visualizar
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div v-else>
          <form
            class="modal-body"
            @submit.prevent="acceptTerm"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-5">
                    <strong>Termo válido a partir de:</strong>
                  </div>
                  <div class="col-7">
                    {{ formatDatetimeBR(termOfUse.created_at) }}
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col text-break"
                    style="line-height: 20px; white-space: break-spaces;"
                  >
                    <strong>Descrição:</strong>
                    <br />{{termOfUse.description }}
                  </div>
                </div>
                <div v-if="termOfUse.attachment" class="row">
                  <a
                    class="text-info me-3"
                    :href="termOfUse.attachment"
                    target="_blank"
                  >
                    Visualizar anexo
                    <eva-icon class="icon nav-icon" name="attach-2-outline"></eva-icon>
                  </a>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col text-end">
                <button
                  class="btn btn-success"
                  data-bs-target="#success-btn"
                  id="btn-save-event"
                  type="submit"
                >
                  <i class="bx bx-check me-1"></i>
                  Aceitar Termo de Uso
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  div .striped {
    border-radius: 0.5rem;
    padding: 5px;
    cursor: pointer;
  }

  div .striped:hover {
    background-color: rgba(124, 124, 124, 0.1);
  }

  /* div .striped:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.09);
  } */
</style>