import storage from '@/storage'
import { copyToClipboard } from '@/utils/browser'
import { Toasts } from '../../../utils/toast-alerts'

export const copyContestLink = (contest) => {
  const user = storage.getItem('user')
  const domain = typeof user.domains === 'string' ? user.domains.split(',')[0] : user.domains[0]
  if (domain.length > 5 && domain.includes('//')) {
    const slug = contest.slug
    copyToClipboard([domain, slug].join('/'))
    Toasts('success', 'Link do sorteio copiado!')
  }
}
